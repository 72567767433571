import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { show_alert } from '../functions';

const ShowUsers = () => {
    const url = 'https://api.navalstrike.com/users';
    const [users, setUsers] = useState([]);
    const [userid, setUserID] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const response = await axios.get(url);
        setUsers(response.data);
    }

    return (
        <div className='App'>
            <h1>Developing...</h1>
        </div>
    )
}

export default ShowUsers